<template>
  <div v-if="GlobalStore.isWindowsOs" class="platform" :class="{ active: isActive }">
    <h3 class="platform__title">{{ $t('platformWindows.title') }}</h3>
    <div class="platform__content" v-show="isActive">
      <div class="platform__description">{{ $t('platformWindows.description') }}</div>
      <div class="platform__btn btn btn--primary"><span class="btn-text">{{ $t('platformWindows.getApps') }}</span></div>
      <div class="platform__btn btn btn--secondary"><span class="btn-text">{{ $t('platformWindows.addToApps') }}</span></div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PlatformWindows',
  inject: ['GlobalStore'],
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  }
})
</script>

<style lang="scss" scoped>
.platform__title::before {
  background-image: url('~@/assets/platforms/windows-store.svg');
}
</style>
