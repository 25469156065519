
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PlatformWindows',
  inject: ['GlobalStore'],
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  }
})
