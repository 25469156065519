export default {
  error404: {
    number: '404',
    title: 'Oops, not found!',
    text: 'Sorry, the app you were looking for is no longer here'
  },
  loading: {
    title: 'Loading App',
    text: 'Promptly retrieving your app from the cloud'
  },
  logo: {
    alt: 'OneReach.ai logo'
  },
  platformBex: {
    title: 'In the browser extension',
    description: 'OneReach.ai browser extension enables apps with push notifications and voice capabilities. Additionally, it allows you to see the apps you’ve added, manage them and browse their notification and call history.',
    getBrowserExtension: 'Get browser extension',
    addToBrowserExtension: 'Add to browser extension',
    openWithBrowserExtension: 'Open with browser extension'
  },
  platformMacos: {
    title: 'In the desktop app',
    description: 'OneReach.ai desktop applications enables apps with push notifications and voice capabilities. Additionally, it allows you to see the apps you\'ve added, manage them and browse their notification and call history.',
    getApps: 'Get OneReach.ai Apps',
    addToApps: 'Add to apps'
  },
  platformMobile: {
    title: 'On your smartphone',
    description: 'OneReach.ai mobile apps enable apps with push notifications and voice capabilities. Additionally, it allows you to see the apps you’ve added, manage them and browse their notification and call history.\nScan this QR code with your smartphone to add this app on your smartphone.',
    qrCode: 'QR code'
  },
  platformWeb: {
    title: 'In the new tab',
    description: 'When run in a browser apps do not currently support push notifications and voice. Consider choosing any of the other available options if you\'d like this app to be able to leverage these capabilities. We\'re working hard to implement support for these features in browsers.',
    openApp: 'Open app'
  },
  platformWindows: {
    title: 'In the desktop app',
    description: 'OneReach.ai desktop applications enables apps with push notifications and voice capabilities. Additionally, it allows you to see the apps you\'ve added, manage them and browse their notification and call history.',
    getApps: 'Get OneReach.ai Apps',
    addToApps: 'Add to apps'
  },
  shareDesktop: {
    chooseHowToRun: 'Choose how you want to run this app:',
    whatIsTheDifference: 'What is the difference?',
    description: 'As of right now not all of the capabilities are supported across all of the platforms. Specific details are listed against each of the options above. We\'re working hard to achieve parity in this regard.'
  },
  shareMobile: {
    description: 'OneReach.ai mobile apps enable apps with push notifications and voice capabilities. Additionally, it allows you to see the apps you\'ve added, manage them and browse their notification and call history.',
    addToMyApps: 'Add to my apps',
    openInBrowser: 'Open in Browser',
    noAppInstalled: 'No app installed?',
    downloadApp: 'Download OneReach.ai app',
    step1: '1. Download OneReach.ai app',
    step2: '2. Add your bot once app is installed'
  }
}
