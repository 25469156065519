<template>
  <div class="app-info">
    <div class="app-info__img" :style="{
      backgroundColor: `#${appData.color}`,
      backgroundImage: `${appData.avatar ? 'url(\'' + appData.avatar + '\')' : 'none'}`
    }"></div>
    <div class="app-info__text">
      <h1 class="app-info__name">{{ appData.name }}</h1>
      <div class="app-info__description">{{ appData.description }}</div>
    </div>
  </div>
</template>

<script lang="ts">
import { IApp } from '@/interfaces'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'AppInfo',
  props: {
    appData: {
      type: Object as PropType<IApp>,
      required: true
    }
  }
})
</script>

<style scoped lang="scss">
.app-info {
  display: flex;
  flex-shrink: 0;

  @media (max-width: $phone-width) {
    flex-direction: column;
    align-items: center;
  }
}
.app-info__img {
  width: 86px;
  height: 86px;
  margin-right: 24px;
  flex-shrink: 0;
  border: 1px solid $color-silver;
  border-radius: 50%;
  overflow: hidden;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  @media (max-width: $phone-width) {
    width: 60px;
    height: 60px;
    margin: 0;
  }
}
.app-info__name {
  margin: 0;
  font-size: 2rem;

  @media (max-width: $phone-width) {
    text-align: center;
    font-size: 1.21rem;
    margin-top: 16px;
  }
}
.app-info__description {
  margin-top: 10px;
  font-size: 1rem;
  line-height: 1.5;
}
</style>
