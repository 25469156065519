<template>
  <div class="wrapper" :class="{ 'android': GlobalStore.isAndroidOs, 'iOS': GlobalStore.isIosOs }">
    <AppInfo :appData="appData"></AppInfo>
    <div v-if="currentStep === 1"  class="mobile__step1">
      <p class="mobile__description">{{ $t('shareMobile.description') }}</p>
      <a :href="appData.fallbackLink" class="mobile__btn mobile__btn-add btn btn--primary" @click="currentStep = 2"><span class="btn-text">{{ $t('shareMobile.addToMyApps') }}</span></a>
      <a :href="appData.url"
        class="mobile__btn mobile__btn-open btn"
        :class="{ 'btn--tertiery': GlobalStore.isAndroidOs, 'btn--secondary': !GlobalStore.isAndroidOs }"
      ><span class="btn-text">{{ $t('shareMobile.openInBrowser') }}</span></a>
    </div>
    <div v-else class="mobile__step2">
      <h2 class="section-header">{{ $t('shareMobile.noAppInstalled') }}</h2>
      <p>{{ $t('shareMobile.step1') }}</p>
      <p v-if="GlobalStore.isIosOs"><a class="app-store" href="https://apps.apple.com/app/onereach-ai-apps/id1474221207" target="_blank" @click="copyShareUrlToClipboard">{{ $t('shareMobile.downloadApp') }}</a></p>
      <p v-if="GlobalStore.isAndroidOs"><a class="google-play" href="https://play.google.com/store/apps/details?id=ai.onereach.apps" target="_blank" @click="copyShareUrlToClipboard">{{ $t('shareMobile.downloadApp') }}</a></p>
      <p>{{ $t('shareMobile.step2') }}</p>
      <a :href="appData.fallbackLink" class="mobile__btn btn btn--primary"><span class="btn-text">{{ $t('shareMobile.addToMyApps') }}</span></a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import AppInfo from '@/components/AppInfo.vue'
import { IApp } from '@/interfaces'

export default defineComponent({
  name: 'ShareMobile',
  components: {
    AppInfo
  },
  inject: ['GlobalStore'],
  props: {
    appData: {
      type: Object as PropType<IApp>,
      required: true
    }
  },
  data () {
    return {
      currentStep: 1
    }
  },
  methods: {
    copyShareUrlToClipboard () {
      navigator.clipboard.writeText(this.appData.shareUrl)
    }
  }
})
</script>

<style scoped lang="scss">
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 96px;

  @media (max-width: $phone-width) {
    justify-content: start;
    margin: 85px 16px 0;
  }
}

.mobile__step1 {
  margin-top: 8px;

  @media (max-width: $phone-width) {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
  }
}

.mobile__description {
  margin: 24px 0;
  line-height: 1.5;

  @media (max-width: $phone-width) {
    margin: 16px 0;
    order: 1;
  }
}

.mobile__btn {
  margin-right: 16px;

  @media (max-width: $phone-width) {
    &.mobile__btn-add {
      order: 0;
    }

    &.mobile__btn-open {
      order: 2;
    }
  }

  .btn-text {
    min-width: 155px;
  }
}

.section-header {
  margin: 24px 0 0;
  font-size: 1.3rem;
  font-weight: 600;
}

.app-store {
  display: inline-block;
  width: 132px;
  height: 44px;
  text-indent: -9999em;
  background-image: url('~@/assets/app-store.svg');
  background-size: cover;
  background-repeat: no-repeat;
}
.google-play {
  display: inline-block;
  width: 176px;
  height: 55px;
  text-indent: -9999em;
  background-image: url('~@/assets/google-play.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.android {
  .btn {
    font-family: roboto, sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1.25px;
  }
  .mobile__btn-open {
    margin-top: 10px;
  }
}
</style>
