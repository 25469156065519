
import { defineComponent, PropType } from 'vue'
import AppInfo from '@/components/AppInfo.vue'
import { IApp } from '@/interfaces'

export default defineComponent({
  name: 'ShareMobile',
  components: {
    AppInfo
  },
  inject: ['GlobalStore'],
  props: {
    appData: {
      type: Object as PropType<IApp>,
      required: true
    }
  },
  data () {
    return {
      currentStep: 1
    }
  },
  methods: {
    copyShareUrlToClipboard () {
      navigator.clipboard.writeText(this.appData.shareUrl)
    }
  }
})
