<template>
  <Logo/>
  <Loading v-if="isLoading" />
  <Error404 v-else-if="isError404" />
  <ShareMobile v-else-if="GlobalStore.isMobile" :appData="appData"/>
  <ShareDesktop v-else :appData="appData"/>
</template>

<script lang="ts">
import * as env from '@/../deploy/config/env.json'
import axios from 'axios'
import { defineComponent } from 'vue'
import Logo from '@/components/Logo.vue'
import Loading from '@/views/Loading.vue'
import Error404 from '@/views/Error404.vue'
import ShareDesktop from '@/views/ShareDesktop.vue'
import ShareMobile from '@/views/ShareMobile.vue'
import { IApp } from '@/interfaces'
import { DARK_MODE_MEDIA_RULE, MOBILE_MODE_MEDIA_RULE } from '@/consts'

export default defineComponent({
  name: 'App',
  components: {
    Logo,
    Loading,
    Error404,
    ShareDesktop,
    ShareMobile
  },
  inject: ['GlobalStore'],
  data () {
    return {
      appData: {} as IApp,
      isLoading: true,
      isError404: false
    }
  },
  created (this: any) {
    // todo add features support and get rid of the `feature.` removal
    const FEATURE_REGEX = /feature\./i
    const APPS_UI_URL = env.APPS_UI_URL.replace(/"/g, '').replace(FEATURE_REGEX, '')
    const APPS_UI_HOSTNAME = (new URL(APPS_UI_URL)).hostname
    const SDK_API_URL = env.SDK_API_URL.replace(/"/g, '').replace(FEATURE_REGEX, '')
    const PROVIDERS_ACCOUNT_ID = env.PROVIDERS_ACCOUNT_ID.replace(/"/g, '')
    const ENV_URL = `${SDK_API_URL}/http/${PROVIDERS_ACCOUNT_ID}/2042/app`
    const APP_ID = `${document.location.pathname.substr(1)}.${APPS_UI_HOSTNAME}`
    const show404 = () => {
      this.isError404 = true
      this.isLoading = false
      document.title = 'App Not Found'
    }

    if (APP_ID) {
      document.title = 'Loading App...'
      axios.get(`${ENV_URL}?id=${APP_ID}`)
        .then(({ data }: { data: IApp }) => {
          if (data.isActive !== false) {
            this.appData = data
            this.appData.fallbackLink = `onereach://app/${APPS_UI_HOSTNAME}/${data.id}`
            this.$watch('GlobalStore', () => {
              this.appData.qrcode = `${ENV_URL}/qr?id=${APP_ID}${this.GlobalStore.isDarkMode ? '' : '&darkColor=000000'}`
            }, { immediate: true, deep: true })

            document.title = this.appData.name
            this.isLoading = false
          } else {
            show404()
          }
        })
        .catch(() => {
          show404()
        })
    } else {
      show404()
    }
  },
  methods: {
    onDarkModeChanged (this: any, ev: any): void {
      this.GlobalStore.isDarkMode = ev.matches
    },
    onMobileModeChanged (this: any, ev: any): void {
      this.GlobalStore.isMobile = ev.matches
    }
  },
  mounted (this: any) {
    window.matchMedia(DARK_MODE_MEDIA_RULE).addEventListener('change', this.onDarkModeChanged)
    window.matchMedia(MOBILE_MODE_MEDIA_RULE).addEventListener('change', this.onMobileModeChanged)
  },
  unmounted () {
    window.matchMedia(DARK_MODE_MEDIA_RULE).removeEventListener('change', this.onDarkModeChanged)
    window.matchMedia(MOBILE_MODE_MEDIA_RULE).removeEventListener('change', this.onMobileModeChanged)
  }
})
</script>

<style lang="scss">
@import '~@/style/buttons.scss';
@import '~@/style/fonts.scss';

html {
  height: 100%;
  min-height: 100%;
  height: auto !important;
  -webkit-overflow-scrolling: touch;
  font-size: 14px;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
}

body {
  height: 100%;
  margin: 0;
  overflow-y: scroll;
  background-color: $color-wild-sand;
  color: $color-shark;

  @media (prefers-color-scheme: dark) {
    background-color: $color-dark;
    color: $color-wild-sand;
  }
}

#app {
  margin: 0 auto;
  width: 100%;
  padding: 0 15px;
  box-sizing: border-box;
}
</style>
