<template>
  <div class="platform" :class="{ active: isActive }">
    <h3 class="platform__title">{{ $t('platformWeb.title') }}</h3>
    <div class="platform__content" v-show="isActive">
      <div class="platform__description">{{ $t('platformWeb.description') }}</div>
      <a :href="appData.url" target="_blank" class="platform__btn btn btn--primary"><span class="btn-text">{{ $t('platformWeb.openApp') }}</span></a>
    </div>
  </div>
</template>

<script lang="ts">
import { IApp } from '@/interfaces'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'PlatformWeb',
  props: {
    appData: {
      type: Object as PropType<IApp>,
      required: true
    },
    isActive: {
      type: Boolean,
      default: false
    }
  }
})
</script>

<style lang="scss" scoped>
.platform__title::before {
  background-image: url('~@/assets/platforms/external-link.svg');

  @media (prefers-color-scheme: dark) {
    background-image: url('~@/assets/platforms/external-link-dark.svg');
  }
}
</style>
