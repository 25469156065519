import { createApp, reactive } from 'vue'
import { createI18n } from 'vue-i18n'
import messages from '@/i18n'
import App from './App.vue'
import Bowser from 'bowser'
import { SUPPORTED_BROWSER, SUPPORTED_OS } from '@/interfaces'
import { DARK_MODE_MEDIA_RULE, MOBILE_MODE_MEDIA_RULE } from '@/consts'

const browser = Bowser.getParser(window.navigator.userAgent)
const browserName = browser?.getBrowserName()
const osName = browser?.getOSName()

const GlobalStore = reactive({
  isAndroidOs: osName === SUPPORTED_OS.ANDROID,
  isChromeBrowser: browserName === SUPPORTED_BROWSER.CHROME,
  isChromeExtensionCompatibleBrowser: browserName === SUPPORTED_BROWSER.CHROME || browserName === SUPPORTED_BROWSER.EDGE,
  isDarkMode: !!(window.matchMedia && window.matchMedia(DARK_MODE_MEDIA_RULE).matches),
  isEdgeBrowser: browserName === SUPPORTED_BROWSER.EDGE,
  isIosOs: osName === SUPPORTED_OS.IOS,
  isMobile: !!(window.matchMedia && window.matchMedia(MOBILE_MODE_MEDIA_RULE).matches),
  isMacOS: osName === SUPPORTED_OS.MACOS,
  isWindowsOs: osName === SUPPORTED_OS.WINDOWS
})

const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages
})

createApp(App)
  .use(i18n)
  .provide('GlobalStore', GlobalStore)
  .mount('#app')
