<template>
  <div class="js-loading-section l-loading">
    <h4 class="loading__tl">{{ $t('loading.title') }}</h4>
    <div class="linear-activity">
      <div class="indeterminate"></div>
    </div>
    <p class="loading__dc">{{ $t('loading.text') }}</p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Loading'
})
</script>

<style scoped lang="scss">
.l-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  height: auto !important;
  min-height: 100vh;
  box-sizing: border-box;
}

.loading__tl {
  font-size: 18px;
}
.loading__dc {
  font-size: 14px;
  color: $color-manatee;
}
.linear-activity {
  overflow: hidden;
  width: 432px;
  max-width: 90%;
  height: 4px;
  background-color: $color-silver;
  margin: 0.8rem auto;
}
.indeterminate {
  position: relative;
  width: 100%;
  height: 100%;
}
.indeterminate::before {
  content: '';
  position: absolute;
  height: 100%;
  background-color: $color-curious-blue;
  animation: indeterminate_first 1.5s infinite ease-out;
  border-radius: 2px;
}
.indeterminate::after {
  content: '';
  position: absolute;
  height: 100%;
  background-color: $color-curious-blue;
  animation: indeterminate_second 1.5s infinite ease-in;
  border-radius: 2px;
}
</style>

// global style because of the bug with scoped animation
<style lang="scss">
@keyframes indeterminate_first {
  0% {
      left: -100%;
      width: 100%;
  }
  100% {
      left: 100%;
      width: 10%;
  }
}
@keyframes indeterminate_second {
  0% {
      left: -150%;
      width: 100%;
  }
  100% {
      left: 100%;
      width: 10%;
  }
}
</style>
