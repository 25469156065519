export enum PLATFORM_TYPE {
  BEX = 'PlatformBex',
  // todo add support for Macos, Windows
  // MACOS = 'PlatformMacos',
  // WINDOWS = 'PlatformWindows',
  MOBILE = 'PlatformMobile',
  WEB = 'PlatformWeb'
}

export enum SUPPORTED_BROWSER {
  CHROME = 'Chrome',
  EDGE = 'Microsoft Edge'
}

export enum SUPPORTED_OS {
  ANDROID = 'Android',
  IOS = 'iOS',
  MACOS = 'macOS',
  WINDOWS = 'Windows'
}

export interface IPlatform {
  componentName: PLATFORM_TYPE;
  isActive: boolean;
}

export interface IApp {
  id: string;
  name: string;
  description?: string;
  avatar?: string;
  color: string;
  isActive?: boolean;
  url?: string;
  shareUrl: string;
  shareShortUrl: string;
  qrcode: string;
  fallbackLink: string;
  platforms: IPlatform[];
  activePlatform: IPlatform;
}
